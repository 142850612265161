
ScrollReveal().reveal('.main__text-area', {
    duration: 3000, // アニメーションの完了にかかる時間
    viewFactor: 0, // 0~1,どれくらい見えたら実行するか
    reset:false   // 何回もアニメーション表示するか
});

document.addEventListener('DOMContentLoaded', function () {
    const main = new Main();
});

class Main {
    constructor() {
        this._init();
    }

    _init() {
        new MobileMenu();
        
    }
    _scrollInit() {
        this.observers = new ScrollObserver('.cover-slide', this._inviewAnimation);
    }

}




// スクロール
var pagetopBtn = $('.footer__scroll');
    pagetopBtn.hide();
 
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            pagetopBtn.fadeIn();
        } else {
            pagetopBtn.fadeOut();
        }
    });
 
    $(window).scroll(function () {
        var height = $(document).height();
        var position = $(window).height() + $(window).scrollTop();
        var footer = $("footer").outerHeight();
        if ( height - position  < footer) {
            pagetopBtn.css({
                bottom : 150
            });
        } else {
            pagetopBtn.css({
                position : "fixed",
                bottom: 30
            });
        }
    });

    pagetopBtn.click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });
 
 

//ios対応
$(function () {
    const ua = navigator.userAgent.toLowerCase();
    // alert(ua);
    if (ua.indexOf('iphone') > -1 || (ua.indexOf('android') > -1 && ua.indexOf('mobile') > -1)) {
        // スマートフォン
        // alert('スマートフォン');
        $('body').addClass('is-ios');
  
    } else if (ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document) {
        // タブレット
        // alert('タブレット');
        $('body').addClass('is-ios');
    } else {
        // PC
        // alert('PC');
        $('body').removeClass('is-ios');
    }
})

